// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";
import { placements } from '@popperjs/core';
import Shepherd from "shepherd.js";
// import 'shepherd.js/dist/css/shepherd.css';

export default class extends Controller {
  // static targets = ['role'];
  static values = {
    role: String,
    unlocked: Boolean,
    userId: Number,
  };

  checkTourStatus = () => {
    if (this.tour && this.tour.isActive()) {
      console.log("Cancelling the existing active tour.");
      this.tour.cancel();
      this.tour = null;
    }
    this.initializeTour();
  };

  connect() {
    window.addEventListener("popstate", this.checkTourStatus);
    this.initializeTour();
  }

  disconnect() {
    window.removeEventListener("popstate", this.checkTourStatus);
    if (this.tour) {
      this.tour.cancel();
      this.tour = null;
    }
  }


  initializeTour() {
    // Grab the role of the current user from the HTML
    const role = this.roleValue;
    const unlocked = this.unlockedValue;
    const userId = this.userIdValue;

    if (this.tour && this.tour.isActive()) {
      console.log("A tour is already active, continuing the existing tour.");
      return;
    }

    this.tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: "patient-intro",
        scrollTo: true,
        modalOverlayOpeningPadding: 40,
        modalOverlayOpeningRadius: 20,
        modalOverlayOpeningYOffset: 100,
      },
    });
    this.tour.addStep({
      id: "first-step",
      text:`
          <div class="d-flex align-items-start justify-content-between">
          <div class="pe-3 pt-3">
          <p class="small">Dies ist eine gemeinsame Tagesansicht für dich und deine:n  ${
          role === "therapist" ? "Patient:in" : "Behandler:in"
          }.
          </br>
          </br>
          Hier findest du deine Tagesaufgaben und du kannst täglich alles dokumentieren was du in der nächsten Sitzung besprechen möchtest.
          </br>
          </br>
          <strong>Tipp:</strong> Markiere Aufgaben als erledigt, wenn du sie für heute abgeschlossen hast. Die Einstellung dazu findest du unter den 3 Punkten auf der Aufgabenkarte.</p>
          </div>
          <div class="pt-3">
          <img src="/assets/minimes/tour.svg" style="width: 50px; float:right;"></img>
          </div>
          </div>`,
      classes: "example-step-extra-class",
      buttons: [
        {
          text: '<span class="intro-skip" id="intro-skip">Überspringen</span>',
          action: () => {
              this.tour.cancel();
              window.location = "/disable_intro";
            },
          classes: "custom-intro-skip-class",
        },
        {
          text: "Weiter",
          action: () => {
            this.tour.next();
          },
        },
      ],
      when: {
        show() {
          const currentStepElement = this.tour.currentStep.el;
          const text = currentStepElement.querySelector(".shepherd-text");
          const progress = document.createElement("div");
          progress.className = "shepherd-progress mb-3";
          const progressBar = document.createElement("div");
          progressBar.className = "shepherd-patient-progress-bar";
          progressBar.style.width = `14.3%`;
          // Append the progress bar fill to the progress bar container
          progress.appendChild(progressBar);
          const headline = document.createElement("p");
          headline.className = "section-title text-main-dark mb-2";
          headline.innerText = "tour";
          text.insertAdjacentElement("afterbegin", progress);
          text.insertAdjacentElement("afterbegin", headline);
        },
      },
    });
    this.tour.addStep({
      id: "second-step",
      arrow: false,
      text:
        `<div class="d-flex align-items-start justify-content-between">
          <div class="pe-3 pt-3">
          <p class="small">Hier können Einträge und Beobachtungen für den jetzigen Zeitpunkt festgehalten oder auch für die nächsten Tage und Wochen geplant werden.
        </br>
        </br>
        Alle Beobachtungen, die keiner Kategorie zuzuordnen sind, können als Notiz hinterlegt werden.</p>
          </div>
          <div class="pt-3">
          <img src="/assets/minimes/tour.svg" style="width: 50px; float:right;"></img>
          </div>
          </div>`
        ,
      buttons: [
        {
          text: '<span class="intro-skip" id="intro-skip">Überspringen</span>',
          action: () => {
              this.tour.cancel();
              window.location = "/disable_intro";
            },
          classes: "custom-intro-skip-class",
        },
        {
          text: "Weiter",
          action: () => {
            this.tour.next();
          },
        },
      ],
      attachTo: {
        element: "#entryButton",
      },
      modalOverlayOpeningPadding: 80,
      when: {
        show() {
          const currentStepElement = this.tour.currentStep.el;
          const text = currentStepElement.querySelector(".shepherd-text");
          const progress = document.createElement("div");
          progress.className = "shepherd-progress mb-3";
          const progressBar = document.createElement("div");
          progressBar.className = "shepherd-patient-progress-bar";
          progressBar.style.width = `28.6%`;
          // Append the progress bar fill to the progress bar container
          progress.appendChild(progressBar);
          const headline = document.createElement("p");
          headline.className = "section-title text-main-dark mb-2";
          headline.innerText = "tour";
          text.insertAdjacentElement("afterbegin", progress);
          text.insertAdjacentElement("afterbegin", headline);
        },
      },
    });
    if (unlocked || (!unlocked && role === "therapist")) {
      this.tour.addStep({
        id: "third-step",
        arrow: false,
        text:
          `<div class="d-flex align-items-start justify-content-between">
          <div class="pe-3 pt-3">
          <p class="small">Hier können Therapieziele und Werte festgelegt werden. Diese können im Laufe der Therapie immer wieder angepasst werden.
          </br>
          </br>In den Therapiezielen kann auch der aktuelle Fortschritt dokumentiert werden, um die Entwicklung zu verfolgen und motiviert zu bleiben.
          <br>
          <br>
          Dieser Bereich könnte je nach Abonnement der Behandler:in für Patient:innen nicht sichtbar sein.
          </p>
          </div>
          <div class="pt-3">
          <img src="/assets/minimes/tour.svg" style="width: 50px; float:right;"></img>
          </div>
          </div>`,
        buttons: [
          {
            text: '<span class="intro-skip" id="intro-skip">Überspringen</span>',
            action: () => {
                this.tour.cancel();
                window.location = "/disable_intro";
              },
            classes: "custom-intro-skip-class",
          },
          {
            text: "Weiter",
            action: () => {
              this.tour.next();
            },
          },
        ],
        attachTo: {
          element: "#taskButton",
        },
        when: {
          show() {
            const currentStepElement = this.tour.currentStep.el;
            const text = currentStepElement.querySelector(".shepherd-text");
            const progress = document.createElement("div");
            progress.className = "shepherd-progress mb-3";
            const progressBar = document.createElement("div");
            progressBar.className = "shepherd-patient-progress-bar";
            progressBar.style.width = `42.8%`;
            // Append the progress bar fill to the progress bar container
            progress.appendChild(progressBar);
            const headline = document.createElement("p");
            headline.className = "section-title text-main-dark mb-2";
            headline.innerText = "tour";
            text.insertAdjacentElement("afterbegin", progress);
            text.insertAdjacentElement("afterbegin", headline);
          },
        },
      });
      this.tour.addStep({
        id: "fourth-step",
        arrow: false,
        text:
          `<div class="d-flex align-items-start justify-content-between">
          <div class="pe-3 pt-3">
          <p class="small">Hier findest du eine Übersicht deiner Ressourcen. Füge neue Ressourcen hinzu und markiere sie als aktivert, wenn sie genutzt wurden. Erst dann tauchen sie auf deinem Dashboard auf.
          </br>
          </br> Dieser Bereich könnte je nach Abonnement der Behandler:in für Patient:innen nicht sichtbar sein.
          </p>
          </div>
          <div class="pt-3">
          <img src="/assets/minimes/tour.svg" style="width: 50px; float:right;"></img>
          </div>
          </div>`,
        buttons: [
          {
            text: '<span class="intro-skip" id="intro-skip">Überspringen</span>',
            action: () => {
                this.tour.cancel();
                window.location = "/disable_intro";
              },
            classes: "custom-intro-skip-class",
          },
          {
            text: "Weiter",
            action: () => {
              this.tour.next();
            },
          },
        ],
        attachTo: {
          element: "#resourceButton",
        },
        when: {
          show() {
            const currentStepElement = this.tour.currentStep.el;
            const text = currentStepElement.querySelector(".shepherd-text");
            const progress = document.createElement("div");
            progress.className = "shepherd-progress mb-3";
            const progressBar = document.createElement("div");
            progressBar.className = "shepherd-patient-progress-bar";
            progressBar.style.width = `57.4%`;
            // Append the progress bar fill to the progress bar container
            progress.appendChild(progressBar);
            const headline = document.createElement("p");
            headline.className = "section-title text-main-dark mb-2";
            headline.innerText = "tour";
            text.insertAdjacentElement("afterbegin", progress);
            text.insertAdjacentElement("afterbegin", headline);
          },
        },
      });
      this.tour.addStep({
        id: "fifth-step",
        arrow: false,
        text:
        `<div class="d-flex align-items-start justify-content-between">
        <div class="pe-3 pt-3">
        <p class="small">Hier findest du die Wochenansicht sowie die gesamte Verlaufsansicht deiner Einträge. Zusammenhänge können so besser erkannt und reflektiert werden.
          </br>
          </br>
         Die Filterfunktion ermöglicht es dir bestimmte Kategorien von Einträgen oder Zeiträumen anzuzeigen. Dadurch lassen sich beispielsweise Einträge zur Stimmung über einen bestimmten Zeitraum anzeigen.
        </br>
        </br> Dieser Bereich könnte je nach Abonnement der Behandler:in für Patient:innen nicht sichtbar sein.
        </p>
        </div>
        <div class="pt-3">
        <img src="/assets/minimes/tour.svg" style="width: 50px; float:right;"></img>
        </div>
        </div>`,
        buttons: [
          {
            text: '<span class="intro-skip" id="intro-skip">Überspringen</span>',
            action: () => {
                this.tour.cancel();
                window.location = "/disable_intro";
              },
            classes: "custom-intro-skip-class",
          },
          {
            text: "Weiter",
            action: () => {
              this.tour.next();
            },
          },
        ],
        attachTo: {
          element: "#weeklylineButton"
        },
        when: {
          show() {
            const currentStepElement = this.tour.currentStep.el;
            const text = currentStepElement.querySelector(".shepherd-text");
            const progress = document.createElement("div");
            progress.className = "shepherd-progress mb-3";
            const progressBar = document.createElement("div");
            progressBar.className = "shepherd-patient-progress-bar";
            progressBar.style.width = `71.7%`;
            // Append the progress bar fill to the progress bar container
            progress.appendChild(progressBar);
            const headline = document.createElement("p");
            headline.className = "section-title text-main-dark mb-2";
            headline.innerText = "tour";
            text.insertAdjacentElement("afterbegin", progress);
            text.insertAdjacentElement("afterbegin", headline);
          },
        },
      });
    }
    if (role === "patient") {
      this.tour.addStep({
        id: "sixth-step",
        text: `
          <div class="d-flex align-items-start justify-content-between">
          <div class="pe-3 pt-3">
          <p class="small">Bevor du dich weiter umguckst, kannst du minime auch als App auf deinem Smartphone hinzufügen. Erhalte eine detaillierte Schritt-für-Schritt Anleitung in deinem <a href="/users/${userId}#accordionFlushExample" class="small text-main-dark fw-bold">Konto</a>.
          </p>
          </div>
          <div class="pt-3">
          <img src="/assets/minimes/tour.svg" style="width: 50px; float:right;"></img>
          </div>
          </div>`,
        classes: "example-step-extra-class",
        buttons: [
          {
            text: '<span class="intro-skip" id="intro-skip">Überspringen</span>',
            action: () => {
                this.tour.cancel();
                window.location = "/disable_intro";
              },
            classes: "custom-intro-skip-class",
          },
          {
            text: "Weiter",
            action: () => {
              this.tour.next();
            },
          },
        ],
        attachTo: {
          element: "#userAccountPatient",
        },
        when: {
          show() {
            const currentStepElement = this.tour.currentStep.el;
            const text = currentStepElement.querySelector(".shepherd-text");
            const progress = document.createElement("div");
            progress.className = "shepherd-progress mb-3";
            const progressBar = document.createElement("div");
            progressBar.className = "shepherd-patient-progress-bar";
            progressBar.style.width = unlocked ? `86%` : '75%';
            // Append the progress bar fill to the progress bar container
            progress.appendChild(progressBar);
            const headline = document.createElement("p");
            headline.className = "section-title text-main-dark mb-2";
            headline.innerText = "tour";
            text.insertAdjacentElement("afterbegin", progress);
            text.insertAdjacentElement("afterbegin", headline);
          },
        },
      });
    }
    this.tour.addStep({
      id: "seventh-step",
      arrow: false,
      text: `
        <img src="/assets/icons/minime.svg" style="width: 50px; float:right;"></img>
        <p>Einträge und Aufträge für die Zeit bis zur nächsten Stunde können hier in der Navigationsleiste erstellt werden.
        </br>
        </br>
        Zwischen den Sitzungen ${
          role === "therapist" ? "lassen sich" : "kannst du"
        } Gefühle, Gedanken und vieles mehr mit nur wenigen Klicks dokumentieren!
        </br>
        </br>
        ${
          role === "therapist"
            ? "Schau dich doch mal um, erstelle einen Auftrag oder klicke dich durch das Positivtagebuch"
            : "Schau dich doch mal um und halte z.B. deine Stimmung in diesem Moment fest!"
        }</p>`,
        buttons: [
          {
            text: '<span class="intro-skip" id="intro-skip">Überspringen</span>',
            action: () => {
                this.tour.cancel();
                window.location = "/disable_intro";
              },
            classes: "custom-intro-skip-class",
          },
          {
            text: "Fertig",
            action: () => {
              this.tour.complete();
            },
          },
        ],
      attachTo: {
        element: "#entryButton",
      },
      modalOverlayOpeningPadding: 80,
      when: {
        show() {
          const currentStepElement = this.tour.currentStep.el;
          const text = currentStepElement.querySelector(".shepherd-text");
          const progress = document.createElement("div");
          progress.className = "shepherd-progress mb-3";
          const progressBar = document.createElement("div");
          progressBar.className = "shepherd-patient-progress-bar";
          progressBar.style.width = `100%`;
          // Append the progress bar fill to the progress bar container
          progress.appendChild(progressBar);
          const headline = document.createElement("p");
          headline.className = "section-title text-main-dark mb-2";
          headline.innerText = "tour";
          text.insertAdjacentElement("afterbegin", progress);
          text.insertAdjacentElement("afterbegin", headline);
        },
      },
    });
    this.tour.start();
  }
}
