import { Controller } from "@hotwired/stimulus";
import Shepherd from "shepherd.js";
// import 'shepherd.js/dist/css/shepherd.css';
export default class extends Controller {
  // static targets = ['output'];
  static values = {
    department: String,
    userId: Number,
  };

  checkTourStatus = () => {
    if (this.tour && this.tour.isActive()) {
      console.log("Cancelling the existing active tour.");
      this.tour.cancel();
      this.tour = null;
    }
    this.#buildTour();
  };

  connect() {
    window.addEventListener("popstate", this.checkTourStatus);
    this.#buildTour();
  }

  disconnect() {
    window.removeEventListener("popstate", this.checkTourStatus);
    if (this.tour) {
      this.tour.cancel();
      this.tour = null;
    }
  }

  #buildTour() {
    const department = this.departmentValue;
    const userId = this.userIdValue;

    if (this.tour && this.tour.isActive()) {
      console.log("A tour is already active, continuing the existing tour.");
      return;
    }

    this.tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: "shadow-md bg-purple-dark",
        // scrollTo: true,
        modalOverlayOpeningPadding: 40,
        modalOverlayOpeningRadius: 20,
        // modalOverlayOpeningYOffset: 100,
      },
    });
    this.tour.addStep({
      id: "first-step",
      text: `
        <div class="d-flex align-items-start justify-content-between">
        <div class="pe-3 pt-3">
        <p class="small">${
          department === "coaching"
            ? "minime ist ein persönliches Coaching-Tagebuch für deine Klient:innen. "
            : ""
        }In dieser Übersicht werden alle ${
          department === "coaching" ? "Klient:innen" : "Patient:innen"
        } angezeigt die du zur App eingeladen hast.<br><br>Neue Einträge deiner ${
          department === "coaching" ? "Klient:innen" : "Patient:innen"
        } werden auf der jeweiligen Akte angezeigt.</p>
        </div>
        <div class="pt-3">
        <img src="/assets/icons/therapist.svg" style="width: 50px; float:right;"></img>
        </div>
        </div>`,
      classes: "example-step-extra-class",
      buttons: [
        {
          text: '<span class="intro-skip" id="intro-skip">Überspringen</span>',
          action: () => {
            this.tour.cancel();
            window.location = "/disable_intro";
          },
          classes: "custom-intro-skip-class",
        },
        {
          text: "Weiter",
          action: () => {
            this.tour.next();
          },
        },
      ],
      when: {
        show() {
          const currentStepElement = this.tour.currentStep.el;
          const text = currentStepElement.querySelector(".shepherd-text");
          const progress = document.createElement("div");
          progress.className = "shepherd-progress mb-3";
          const progressBar = document.createElement("div");
          progressBar.className = "shepherd-progress-bar";
          progressBar.style.width = `25%`;
          // Append the progress bar fill to the progress bar container
          progress.appendChild(progressBar);
          const headline = document.createElement("p");
          headline.className = "section-title text-therapist-dark mb-2";
          headline.innerText = "tour";
          text.insertAdjacentElement("afterbegin", progress);
          text.insertAdjacentElement("afterbegin", headline);
        },
      },
    });
    this.tour.addStep({
      id: "second-step",
      arrow: false,
      text: `
        <div class="d-flex align-items-start justify-content-between">
        <div class="pe-3 pt-3">
        <p class="small">Hier kannst du deine ${
          department === "coaching" ? "Klient:innen" : "Patient:innen"
        }  ganz einfach und anonym per QR-Code oder per Email zur gemeinsamen App einladen.<br><br>Deine ${
          department === "coaching" ? "Klient:innen" : "Patient:innen"
        }  erhalten dann einen Einladungs-Link per E-Mail oder können den QR-Code direkt scannen.</p>
        </div>
        <div class="pt-3">
        <img src="/assets/icons/therapist.svg" style="width: 50px; float:right;"></img>
        </div>
        </div>`,
      buttons: [
        {
          text: '<span class="intro-skip" id="intro-skip">Überspringen</span>',
          action: () => {
            this.tour.cancel();
            window.location = "/disable_intro";
          },
          classes: "custom-intro-skip-class",
        },
        {
          text: "Weiter",
          action: () => {
            this.tour.next();
          },
        },
      ],
      attachTo: {
        element: "#inviteButton",
        on: "bottom",
      },
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 15] } }],
      },
      modalOverlayOpeningRadius: 20,
      modalOverlayOpeningPadding: 20,
      when: {
        show() {
          const currentStepElement = this.tour.currentStep.el;
          const text = currentStepElement.querySelector(".shepherd-text");
          const progress = document.createElement("div");
          progress.className = "shepherd-progress mb-3";
          const progressBar = document.createElement("div");
          progressBar.className = "shepherd-progress-bar";
          progressBar.style.width = `50%`;
          // Append the progress bar fill to the progress bar container
          progress.appendChild(progressBar);
          const headline = document.createElement("p");
          headline.className = "section-title text-therapist-dark mb-2";
          headline.innerText = "tour";
          text.insertAdjacentElement("afterbegin", progress);
          text.insertAdjacentElement("afterbegin", headline);
        },
      },
    });
    this.tour.addStep({
      id: "third-step",
      text: `
        <div class="d-flex align-items-start justify-content-between">
        <div class="pe-3 pt-3">
        <p class="small">Bevor du dich weiter umguckst, kannst du minime auch als App auf deinem Smartphone hinzufügen. Erhalte eine detaillierte Schritt-für-Schritt Anleitung in deinem <a href="/users/${userId}#accordionFlushExample" class="small text-therapist-dark fw-bold">Konto</a>.
        </p>
        </div>
        <div class="pt-3">
        <img src="/assets/icons/therapist.svg" style="width: 50px; float:right;"></img>
        </div>
        </div>`,
      classes: "example-step-extra-class",
      buttons: [
        {
          text: '<span class="intro-skip" id="intro-skip">Überspringen</span>',
          action: () => {
            this.tour.cancel();
            window.location = "/disable_intro";
          },
          classes: "custom-intro-skip-class",
        },
        {
          text: "Weiter",
          action: () => {
            this.tour.next();
          },
        },
      ],
      when: {
        show() {
          const currentStepElement = this.tour.currentStep.el;
          const text = currentStepElement.querySelector(".shepherd-text");
          const progress = document.createElement("div");
          progress.className = "shepherd-progress mb-3";
          const progressBar = document.createElement("div");
          progressBar.className = "shepherd-progress-bar";
          progressBar.style.width = `75%`;
          // Append the progress bar fill to the progress bar container
          progress.appendChild(progressBar);
          const headline = document.createElement("p");
          headline.className = "section-title text-therapist-dark mb-2";
          headline.innerText = "tour";
          text.insertAdjacentElement("afterbegin", progress);
          text.insertAdjacentElement("afterbegin", headline);
        },
      },
    });
    this.tour.addStep({
      id: "fourth-step",
      arrow: false,
      text: `
        <div class="d-flex align-items-start justify-content-between">
        <div class="pe-3 pt-3">
        <p class="small">Durch das Klicken der Karte/Akte, gelangst du direkt in das jeweilige Dashboard deines:r ${
          department === "coaching" ? "Klient:in" : "Patient:in"
        }  <br><br>Alle Einträge werden dort chronologisch, in Echtzeit und für beide Seiten identisch angezeigt.</p>
        </div>
        <div class="pt-3">
        <img src="/assets/icons/therapist.svg" style="width: 50px; float:right;"></img>
        </div>
        </div>`,
      buttons: [
        {
          text: '<span class="intro-skip" id="intro-skip">Überspringen</span>',
          action: () => {
            this.tour.cancel();
            window.location = "/disable_intro";
          },
          classes: "custom-intro-skip-class",
        },
        {
          text: "Fertig",
          action: () => {
            this.tour.complete();
          },
        },
      ],
      attachTo: {
        element: ".patient-card",
        on: "bottom",
      },
      scrollTo: false,
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 15] } }],
      },
      modalOverlayOpeningRadius: 20,
      modalOverlayOpeningPadding: 20,
      when: {
        show() {
          const currentStepElement = this.tour.currentStep.el;
          const text = currentStepElement.querySelector(".shepherd-text");
          const progress = document.createElement("div");
          progress.className = "shepherd-progress mb-3";
          const progressBar = document.createElement("div");
          progressBar.className = "shepherd-progress-bar";
          progressBar.style.width = `100%`;
          // Append the progress bar fill to the progress bar container
          progress.appendChild(progressBar);
          const headline = document.createElement("p");
          headline.className = "section-title text-therapist-dark mb-2";
          headline.innerText = "tour";
          text.insertAdjacentElement("afterbegin", progress);
          text.insertAdjacentElement("afterbegin", headline);
        },
      },
    });
    this.tour.start();
  }
}
