import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleIcon", "text", "column", "buttonDiv","icon", "collapse", "logoLarge", "logoSmall"];

  static classes = ["collapsed", "expanded"];

  connect() {
    this.toggleIconClass()
    this.collapseTarget.addEventListener('shown.bs.collapse', this.showIcon.bind(this))
    this.collapseTarget.addEventListener('hidden.bs.collapse', this.hideIcon.bind(this))

    this.expanded = true; // Set initial state
  }

  toggle() {
    this.expanded = !this.expanded;

    if (this.expanded) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  showIcon() {
    this.iconTarget.classList.remove('fa-bars')
    this.iconTarget.classList.add('fa-times')
  }

  hideIcon() {
    this.iconTarget.classList.remove('fa-times')
    this.iconTarget.classList.add('fa-bars')
  }

  toggleIconClass() {
    if (this.collapseTarget.classList.contains('show')) {
      this.showIcon()
    } else {
      this.hideIcon()
    }
  }

  expand() {
    this.toggleIconTarget.src = "assets/icons/fold-in.svg"; // Change icon to "fold-in"
    this.element.classList.add(this.expandedClass);
    this.element.classList.remove(this.collapsedClass);
    this.columnTarget.classList.add("col-auto");
    this.columnTarget.classList.remove("col-1");
    this.buttonDivTarget.classList.add("justify-content-end");
    this.buttonDivTarget.classList.remove("justify-content-center");

    // Show text by removing d-none
    this.textTargets.forEach((text) => {
      text.classList.remove("d-none");
    });
    if (this.hasLogoLargeTarget) {
    this.logoLargeTarget.classList.remove("d-none");
    }
    if (this.hasLogoSmallTarget) {
    this.logoSmallTarget.classList.add("d-none");
    }
  }

  collapse() {
    this.toggleIconTarget.src = "assets/icons/fold-out.svg"; // Change icon to "fold-out"
    this.element.classList.add(this.collapsedClass);
    this.element.classList.remove(this.expandedClass);
    this.columnTarget.classList.remove("col-auto");
    this.columnTarget.classList.add("col-1");
    this.buttonDivTarget.classList.remove("justify-content-end");
    this.buttonDivTarget.classList.add("justify-content-center");

    // Hide text by adding d-none
    this.textTargets.forEach((text) => {
      text.classList.add("d-none");
    });
    if (this.hasLogoLargeTarget) {
    this.logoLargeTarget.classList.add("d-none");
    }
    if (this.hasLogoSmallTarget) {
    this.logoSmallTarget.classList.remove("d-none");
    }
  }
}
