import PasswordVisibility from '@stimulus-components/password-visibility';

export default class extends PasswordVisibility {
  static targets = ["input", "showIcon", "hideIcon"];

  connect() {
    super.connect();
  }

  toggle(event) {
    super.toggle(event);

    const input = this.inputTarget;
    const showIcon = this.showIconTarget;
    const hideIcon = this.hideIconTarget;

    if (input.type === "password") {
      showIcon.classList.add("hidden");
      hideIcon.classList.remove("hidden");
    } else {
      showIcon.classList.remove("hidden");
      hideIcon.classList.add("hidden");
    }
  }
}
