import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "email",
    "password",
    "passwordRepeat",
    "agb",
    "submitButton",
    "passwordHint",
    "passwordsEqualHint",
  ];

  static values = { login: String };

  connect() {
    if (this.loginValue !== "login") {
      if (this.hasEmailTarget) {
        this.emailTarget.addEventListener("input", this.checkFormFieldsRegister);
      }
      this.passwordTarget.addEventListener(
        "input",
        this.checkFormFieldsRegister,
      );
      this.passwordRepeatTarget.addEventListener(
        "input",
        this.checkFormFieldsRegister,
      );
      if (this.hasAgbTarget) {
        this.agbTarget.addEventListener("change", this.checkFormFieldsRegister);
      }
    } else {
      if (this.hasEmailTarget) {
        this.emailTarget.addEventListener("input", this.checkFormFieldsLogin);
      }
      this.passwordTarget.addEventListener("input", this.checkFormFieldsLogin);
      this.passwordTarget.addEventListener("input", this.showPasswordHint);
      if (this.hasPasswordRepeatTarget) {
        this.passwordRepeatTarget.addEventListener(
          "input",
          this.checkFormFieldsRegister,
        );
      }
    }
  }

  checkFormFieldsLogin = () => {
    const emailValue = this.emailTarget.value.trim();
    const passwordValue = this.passwordTarget.value.trim();

    this.toggleButton(emailValue && passwordValue);
  };

  checkFormFieldsRegister = () => {
    let emailValue = ""; // Declare outside if
    if (this.hasEmailTarget) {
      emailValue = this.emailTarget.value.trim();
    }

    const passwordValue = this.passwordTarget.value.trim();
    const passwordRepeatValue = this.passwordRepeatTarget.value.trim();

    let agbValue = false;
    if (this.hasAgbTarget) {
      agbValue = this.agbTarget.checked;
    }
    if (this.hasPasswordHintTarget) {
      this.togglePasswordHint(
        passwordValue.length > 0 && !this.isValidPassword(passwordValue)
      );
    }

    if (this.hasPasswordsEqualHintTarget) {
      this.togglePasswordsEqualHint(passwordValue !== passwordRepeatValue);
    } else {
      this.togglePasswordRepeatValidity(passwordValue === passwordRepeatValue);
    }

    if (this.hasAgbTarget && this.hasEmailTarget) {

      this.toggleButton(
        emailValue &&
        passwordValue &&
        passwordRepeatValue &&
        agbValue &&
        this.isValidPassword(passwordValue) &&
        passwordValue === passwordRepeatValue
      );
    } else {
      this.toggleButton(
        passwordValue &&
        passwordRepeatValue &&
        this.isValidPassword(passwordValue) &&
        passwordValue === passwordRepeatValue
      );
    }
  };

  showPasswordHint = () => {
    const passwordValue = this.passwordTarget.value.trim();
    this.togglePasswordHint(
      passwordValue.length > 0 && !this.isValidPassword(passwordValue),
    );
  };

  togglePasswordHint = (show) => {
    if (this.hasPasswordHintTarget) {
      this.passwordHintTarget.style.display = show ? "block" : "none";
    } else {
    return;
    }
  };

  togglePasswordsEqualHint = (show) => {
    this.passwordsEqualHintTarget.style.display = show ? "block" : "none";
  }

  togglePasswordRepeatValidity = (isValid) => {
    if (isValid) {
      this.passwordRepeatTarget.classList.remove("is-invalid");
    } else {
      this.passwordRepeatTarget.classList.add("is-invalid");
    }
  };

  toggleButton = (enable) => {
    if (enable) {
      this.submitButtonTarget.classList.remove("disabled");
    } else {
      this.submitButtonTarget.classList.add("disabled");
    }
  };

  isValidPassword(password) {
    const minLength = 6;
    const hasSpecialChar = /(?=.*[0-9])(?=.*[!@#$%^&*])/.test(password);

    return password.length >= minLength && hasSpecialChar;
  }
}
